import videojs, { VideoJsPlayer } from 'video.js';
import { CueOptions, Cue } from '../../interfaces';
import Cuepoint from './Cuepoint';

const Plugin = videojs.getPlugin('plugin');

class Cuepoints extends Plugin {
  instances: Cuepoint[] = [];

  constructor(player: VideoJsPlayer, options: CueOptions) {
    super(player, options);

    this.init(options);
  }

  init = (options: CueOptions) => {
    options.cuepoints.forEach((cue, index) => {
      const cp = new Cuepoint(this.player, cue, index);

      this.instances.push(cp);
    });
  };

  dispose = () => {
    super.dispose();

    videojs.log('Cuepoints plugin is being disposed');

    this.instances.forEach(cue => {
      cue.destroy();
    });

    this.instances = [];
  };

  /**
   * add cue point instance
   * @param cuePoint
   */
  add = (cuePoint: Cue) => {
    const index = this.instances.length;
    const cp = new Cuepoint(this.player, cuePoint, index);
    this.instances.push(cp);

    this.instances.sort((currentCue, nextCue) => currentCue.start - nextCue.start);

    this.instances.forEach((cuePoint, index) => {
      cuePoint.idx = index;
    });
  };

  /**
   * remove cue point instance
   * @param indexOfInstance
   */
  remove = (index: number) => {
    this.instances.splice(index, 1);

    this.instances.forEach((cuePoint, index) => {
      cuePoint.idx = index;
    });
  };

  /**
   * remove all instances
   */
  removeAll = () => {
    this.instances.forEach(cue => {
      cue.destroy();
    });
    this.instances = [];
  };

  /**
   * get all instances
   */
  getAll = () => {
    return this.instances;
  };

  updateCue = (cue: Cue) => {
    const cueIndex = this.instances.findIndex(cp => cp.id === cue.id);

    if (cueIndex) {
      this.instances[cueIndex].destroy();

      const cp = new Cuepoint(this.player, cue, cueIndex);

      this.instances = [...this.instances.slice(0, cueIndex), cp, ...this.instances.slice(cueIndex + 1)];
    }
  };
}

videojs.registerPlugin('cuepoints', Cuepoints);
