import { VideoJsPlayer } from 'video.js';
import { Cue, CueParams } from '../../interfaces';

class Cuepoint {
  active = false;
  triggered = false;
  id: string;
  idx: number;
  onStart?: (id: string, index: number) => void;
  params?: CueParams;
  player: VideoJsPlayer;
  start: number;

  constructor(player: VideoJsPlayer, cue: Cue, idx: number) {
    this.player = player;

    this.id = cue.id;
    this.idx = idx;
    this.onStart = cue.onStart;
    this.params = cue.params;
    this.start = cue.start;
  }

  _timeUpdateHandler = () => {
    const currentTime = this.player.currentTime();

    if (currentTime >= this.start) {
      if (this.triggered) {
        return;
      }

      this.triggered = true;

      this.onStart && this.onStart(this.id, this.idx);
    } else {
      if (!this.triggered) {
        return;
      }

      this.triggered = false;
    }
  };

  activate = () => {
    !this.active && this.player.on('timeupdate', this._timeUpdateHandler);

    this.active = true;
  };

  destroy = () => {
    this.triggered = false;
    this.active = false;

    this.player.off('timeupdate', this._timeUpdateHandler);
  };

  suspend = () => {
    this.triggered = false;

    this.active && this.player.off('timeupdate', this._timeUpdateHandler);

    this.active = false;
  };
}

export default Cuepoint;
