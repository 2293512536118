/* eslint-disable */

import React, { Component, ErrorInfo } from 'react';

type Props = {
  children: React.ReactNode;
};

class ErrorBoundary extends Component<Props> {
  state = { hasError: false };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log(error);
    console.log(errorInfo);
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1 className="h1">Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
