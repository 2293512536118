/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import { GTMData } from '../interfaces';
import { GTM_EVENT, GTM_CONFIG, ENVIRONMENT } from '../constants';
import { loadScript } from './';

export const pushToGTMDataLayer = (data: GTMData) => {
  window.dataLayer && window.dataLayer.push(data);
};

export const setGTMData = (payload: GTMData) => {
  let data: GTMData | null = null;

  switch (payload.event) {
    case GTM_EVENT.PLAYER_LOAD:
      data = {
        Application: payload.Application,
        Environment: payload.Environment,
        event: GTM_EVENT.PLAYER_LOAD,
        PersonID: payload.PersonID,
        ProductConsumerID: payload.ProductConsumerID,
        Timestamp: payload.Timestamp,
        UserId: payload.PersonID
      };
      break;

    case GTM_EVENT.MEDIA_LOAD:
      data = {
        Application: payload.Application,
        Environment: payload.Environment,
        event: GTM_EVENT.MEDIA_LOAD,
        MediaID: payload.MediaID,
        MediaType: payload.MediaType,
        PersonID: payload.PersonID,
        ProductConsumerID: payload.ProductConsumerID,
        StreamUrl: payload.StreamUrl,
        Timestamp: payload.Timestamp,
        MediaLength: payload.MediaLength
      };
      break;

    case GTM_EVENT.PLAYBACK_START:
      data = {
        Application: payload.Application,
        Environment: payload.Environment,
        event: GTM_EVENT.PLAYBACK_START,
        MediaID: payload.MediaID,
        MediaType: payload.MediaType,
        PersonID: payload.PersonID,
        ProductConsumerID: payload.ProductConsumerID,
        StreamUrl: payload.StreamUrl,
        Timestamp: payload.Timestamp
      };
      break;

    case GTM_EVENT.PLAYBACK_PAUSE:
      data = {
        Application: payload.Application,
        Environment: payload.Environment,
        event: GTM_EVENT.PLAYBACK_PAUSE,
        MediaID: payload.MediaID,
        MediaType: payload.MediaType,
        PersonID: payload.PersonID,
        ProductConsumerID: payload.ProductConsumerID,
        StreamUrl: payload.StreamUrl,
        Timestamp: payload.Timestamp
      };
      break;

    case GTM_EVENT.PLAYBACK_END:
      data = {
        Application: payload.Application,
        Environment: payload.Environment,
        event: GTM_EVENT.PLAYBACK_END,
        MediaID: payload.MediaID,
        MediaType: payload.MediaType,
        PersonID: payload.PersonID,
        ProductConsumerID: payload.ProductConsumerID,
        StreamUrl: payload.StreamUrl,
        Timestamp: payload.Timestamp
      };
      break;

    case GTM_EVENT.PLAYER_ERROR:
      data = {
        Application: payload.Application,
        Environment: payload.Environment,
        ErrorMessage: payload.ErrorMessage,
        event: GTM_EVENT.PLAYER_ERROR,
        MediaID: payload.MediaID,
        MediaType: payload.MediaType,
        PersonID: payload.PersonID,
        ProductConsumerID: payload.ProductConsumerID,
        StreamUrl: payload.StreamUrl,
        Timestamp: payload.Timestamp
      };
      break;

    default:
      break;
  }

  if (data) {
    pushToGTMDataLayer(data);
  }
};

export const injectGTMScript = (env: string, callback: () => void) => {
  let gtmAuth, envId;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

  switch (env) {
    case ENVIRONMENT.PRD:
    case ENVIRONMENT.PROD:
      gtmAuth = GTM_CONFIG.PROD.AUTH;
      envId = GTM_CONFIG.PROD.VIEW_ID;
      break;

    default:
      gtmAuth = GTM_CONFIG.NON_PROD.AUTH;
      envId = GTM_CONFIG.NON_PROD.VIEW_ID;
      break;
  }

  loadScript(
    'gtm',
    `https://www.googletagmanager.com/gtm.js?id=${GTM_CONFIG.ID}&gtm_auth=${gtmAuth}&gtm_preview=${envId}&gtm_cookies_win=x`,
    callback
  );
};
