/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import ReactDOM from 'react-dom';
import videojs, { VideoJsPlayer } from 'video.js';

const Plugin = videojs.getPlugin('plugin');

type Options = {
  component: JSX.Element;
};

class InteractiveComponentModal extends Plugin {
  modal?: videojs.ModalDialog;
  options?: Options;
  elementId = 'mp-interactive-modal-content';
  modalOpen = false;

  constructor(players: VideoJsPlayer, options?: Options) {
    super(players, options);

    if (options) {
      this.options = options;
    }

    this.init();
  }

  init = () => {
    const ModalDialog = videojs.getComponent('ModalDialog');

    const contentWrapper = document.createElement('div');
    contentWrapper.id = this.elementId;

    this.modal = new ModalDialog(this.player, {
      content: contentWrapper,
      temporary: false,
      uncloseable: true
    });

    this.player.addChild(this.modal);
  };

  open = (options?: Options) => {
    if (options) {
      this.options = options;
    }

    this.modal?.open();

    this.modalOpen = true;

    if (this.options) {
      ReactDOM.render(this.options.component, document.getElementById('mp-interactive-modal-content') as HTMLElement);
    }

    this.player.controls(true);
  };

  update = (options: Options) => {
    this.options = options;

    ReactDOM.render(this.options.component, document.getElementById('mp-interactive-modal-content') as HTMLElement);
  };

  close = () => {
    this.modal?.close();

    this.modalOpen = false;

    ReactDOM.unmountComponentAtNode(document.getElementById('mp-interactive-modal-content') as HTMLElement);
  };

  isOpen = (): boolean => this.modalOpen;

  dispose = () => {
    super.dispose();

    videojs.log('interactiveComponentModal plugin is being disposed');
  };
}

videojs.registerPlugin('interactiveComponentModal', InteractiveComponentModal);
