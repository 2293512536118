import React, { Component } from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import { setGTMData, injectGTMScript } from './utils';
import { GTM_EVENT, REGEX } from './constants';
import { PlayerCallbacks, PlayerOptions, PlayerError, GTMData } from './interfaces';
import ErrorBoundary from './ErrorBoundary';

import 'videojs-contrib-quality-levels';
import 'videojs-max-quality-selector';
import 'videojs-youtube/dist/Youtube.min.js';
import 'videojs-errors';

import './plugins/Cuepoints/Core';
import './plugins/InteractiveComponentModal';
import './plugins/DisableForwardSeek';

import 'video.js/dist/video-js.css';
import 'videojs-max-quality-selector/dist/videojs-max-quality-selector.css';
import 'videojs-errors/dist/videojs-errors.css';
import './styles/styles.scss';

type Props = {
  application: string;
  environment: string;
  options: PlayerOptions;
  playerId: string;
  productConsumerId?: string;
  customErrors?: PlayerError;
} & PlayerCallbacks;

class App extends Component<Props> {
  player?: VideoJsPlayer;

  currentTime = 0;
  startPosition = 0;
  previousTime = 0;
  videoDuration: number | undefined;
  isDaharaMedia = false;

  componentDidMount() {
    injectGTMScript(this.props.environment, () => setGTMData({ ...this.getGtmData(), event: GTM_EVENT.PLAYER_LOAD }));

    this.validateMediaSource();

    this.initPlayer();

    this.registerCallbacks();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps !== this.props && this.props.options.sources !== prevProps.options.sources) {
      this.validateMediaSource();

      this.player?.src(this.props.options.sources || []);
      this.player?.poster(this.props.options.poster || '');
    }

    if (prevProps !== this.props && this.props.options.theme !== prevProps.options.theme) {
      if (!this.props.options.theme) {
        this.player?.removeClass(prevProps.options.theme || '');
      } else {
        prevProps.options.theme && this.player?.removeClass(prevProps.options.theme || '');
        this.player?.addClass(this.props.options.theme || '');
      }
    }
  }

  componentWillUnmount() {
    this.dispose();
  }

  initPlayer = () => {
    const { playerId, options } = this.props;
    if (!options.playbackRates) {
      options.playbackRates =
        options.sources &&
        options.sources.length &&
        options.sources[0].type &&
        options.sources[0].type === 'video/youtube'
          ? [0.5, 0.75, 1, 1.5, 2]
          : [0.5, 0.75, 1, 1.5, 2, 3];
    }

    this.player = videojs(playerId, options);
    this.player.maxQualitySelector({ displayMode: 1 });

    if (this.props.customErrors && Object.keys(this.props.customErrors).length) {
      this.player?.errors({ errors: this.props.customErrors });
    }
  };

  dispose = () => {
    if (this.player) {
      this.player.dispose();

      this.player = undefined;
    }
  };

  registerCallbacks = () => {
    this.registerReadyEvent();
    this.registerLoadStartEvent();
    this.registerLoadedMetadataEvent();

    this.registerPlayEvent();
    this.registerPauseEvent();
    this.registerTimeUpdateEvent();
    this.registerSeekingEvent();
    this.registerSeekedEvent();
    this.registerEndEvent();
    this.registerErrorEvent();
  };

  getCurrentPlayerTime = (): number => Math.round(((this.player?.currentTime() ?? 0) + Number.EPSILON) * 100) / 100;

  registerReadyEvent = () => {
    const { onReady } = this.props;

    this.player?.ready(() => {
      this.player?.src(this.props.options.sources || []);

      if (onReady) {
        this.player && onReady(this.player);
      }
    });
  };

  registerPlayEvent = () => {
    this.player?.on('play', this.onPlay);
  };

  registerPauseEvent = () => {
    this.player?.on('pause', this.onPause);
  };

  registerTimeUpdateEvent = () => {
    const { onTimeUpdate } = this.props;

    this.player?.on('timeupdate', () => {
      const currentPlayerTime = this.getCurrentPlayerTime();

      this.previousTime = this.currentTime;
      this.currentTime = currentPlayerTime;

      if (this.previousTime < this.currentTime) {
        this.startPosition = this.previousTime;
        this.previousTime = this.currentTime;
      }

      onTimeUpdate && onTimeUpdate(currentPlayerTime);
    });
  };

  registerSeekingEvent = () => {
    const { onSeeking } = this.props;

    if (onSeeking) {
      this.player?.on('seeking', () => {
        /* eslint-disable */
        this.player?.off('timeupdate', () => {});
        this.player?.one('seeked', () => {});

        onSeeking(this.getCurrentPlayerTime());
      });
    }
  };

  registerSeekedEvent = () => {
    const { onSeeked } = this.props;

    if (onSeeked) {
      this.player?.on('seeked', () => {
        const endPosition = this.getCurrentPlayerTime();

        onSeeked(this.startPosition, endPosition);

        this.startPosition = endPosition;
      });
    }
  };

  registerEndEvent = () => {
    this.player?.on('ended', this.onEnd);
  };

  registerErrorEvent = () => {
    this.player?.on('error', this.onError);
  };

  registerLoadedMetadataEvent = () => {
    this.player?.on('loadedmetadata', this.onLoadedMetadata);
  };

  registerLoadStartEvent = () => {
    this.player?.on('loadstart', this.onLoadStart);
  };

  onReady = (player: VideoJsPlayer) => {
    const { onReady } = this.props;

    if (onReady) {
      onReady(player);
    }
  };

  onPlay = () => {
    const { onPlay } = this.props;

    if (onPlay) {
      onPlay(this.getCurrentPlayerTime());
    }

    this.isDaharaMedia && setGTMData({ ...this.getGtmData(), event: GTM_EVENT.PLAYBACK_START });
  };

  onPause = () => {
    const { onPause } = this.props;

    if (onPause) {
      onPause(this.getCurrentPlayerTime());
    }

    this.isDaharaMedia && setGTMData({ ...this.getGtmData(), event: GTM_EVENT.PLAYBACK_PAUSE });
  };

  onEnd = () => {
    const { onEnd } = this.props;

    if (onEnd) {
      onEnd();
    }

    this.isDaharaMedia && setGTMData({ ...this.getGtmData(), event: GTM_EVENT.PLAYBACK_END });
  };

  onLoadedMetadata = () => {
    this.videoDuration = this.player?.duration();

    this.isDaharaMedia && setGTMData({ ...this.getGtmData(), event: GTM_EVENT.MEDIA_LOAD });
  };

  onLoadStart = () => {
    this.isDaharaMedia && this.appendACLToken();
  };

  onError = () => {
    this.isDaharaMedia &&
      setGTMData({ ...this.getGtmData(), event: GTM_EVENT.PLAYER_ERROR, ErrorMessage: this.player?.error()?.message });
  };

  getGtmData = (): GTMData => {
    const { options, application, environment, productConsumerId } = this.props;
    const streamUrl = options?.sources?.length ? options?.sources?.[0].src : '';
    let mediaName, mediaId, mediaType;

    if (streamUrl) {
      mediaName = streamUrl.match(REGEX.SEPARATE_QUERY_STRING)?.[1].match(REGEX.EXTRACT_MEDIA_NAME)?.[1];
      mediaId = mediaName?.split('.')[0];
      mediaType = mediaName?.split('.')[1];
    }

    return {
      Application: application,
      Environment: environment,
      event: '',
      MediaID: mediaId ?? '',
      MediaType: mediaType ?? '',
      PersonID: window.piSession?.userId() ?? '',
      ProductConsumerID: productConsumerId ?? '',
      StreamUrl: streamUrl,
      Timestamp: new Date().toString(),
      MediaLength: this.videoDuration
    };
  };

  validateMediaSource = () => {
    const { options } = this.props;

    if (options?.sources && options?.sources[0]) {
      const streamUrl = options?.sources?.[0].src;

      if (streamUrl) {
        this.isDaharaMedia = REGEX.MATCH_DAHARA_SERVICE.test(streamUrl);
      }
    }
  };

  appendACLToken = () => {
    let tokenString = this.player?.src().match(REGEX.SEPARATE_QUERY_STRING)?.[2] || '';

    if (this.player && this.player?.tech().hls && tokenString) {
      this.player.tech().hls.xhr.beforeRequest = (options: any) => {
        return {
          ...options,
          uri: `${options.uri}${tokenString}`
        };
      };
    }
  };

  render() {
    return (
      <ErrorBoundary>
        <div style={{ width: '100%', height: '100%' }}>
          <div data-vjs-player>
            <video
              id={this.props.playerId}
              className={`video-js ${this.props.options.bigPlayButtonCentered && 'vjs-big-play-centered'}`}></video>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

export default App;
