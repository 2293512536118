/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

export const GTM_EVENT = {
  MEDIA_LOAD: 'mediaLoad',
  PLAYBACK_END: 'playbackEnd',
  PLAYBACK_PAUSE: 'playbackPause',
  PLAYBACK_START: 'playbackStart',
  PLAYER_ERROR: 'playerError',
  PLAYER_LOAD: 'playerLoad'
};

export const GTM_CONFIG = {
  ID: 'GTM-546242P',
  PROD: {
    AUTH: 'edBRz9oieo2kMDJJoTWuNQ',
    VIEW_ID: 'env-3'
  },
  NON_PROD: {
    AUTH: 'RbDELAyCB4C26Qyg-tjBcA',
    VIEW_ID: 'env-4'
  }
};
