/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import videojs, { VideoJsPlayer } from 'video.js';

const Plugin = videojs.getPlugin('plugin');

type Options = {
  lastViewedTime: number;
};

class DisableForwardSeek extends Plugin {
  options: Options;
  lastViewedTime = 0;

  constructor(players: VideoJsPlayer, options: Options) {
    super(players, options);

    this.options = { ...options };

    this.init();
  }

  init = () => {
    this.lastViewedTime = this.options.lastViewedTime;

    const disableForwardScrubbing = (player: VideoJsPlayer) => {
      return {
        setSource: (srcObj: any, next: any) => {
          next(null, srcObj);
        },
        setCurrentTime: (ct: number) => {
          const playerCurrentTime = player.currentTime();

          if (playerCurrentTime > this.lastViewedTime) this.lastViewedTime = playerCurrentTime;

          if (ct < playerCurrentTime || ct <= this.lastViewedTime) {
            return ct;
          } else if (ct > this.lastViewedTime) {
            return this.lastViewedTime;
          }

          return playerCurrentTime;
        }
      };
    };

    // Register the middleware function with the players
    videojs.use('*', disableForwardScrubbing);
  };

  updateTime = (options: Options) => {
    this.options = { ...options };

    this.lastViewedTime = this.options.lastViewedTime;
  };

  dispose = () => {
    super.dispose();

    videojs.log('disableForwardSeek plugin is being disposed');
  };
}

videojs.registerPlugin('disableForwardSeek', DisableForwardSeek);
