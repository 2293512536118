/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

export const loadScript = (scriptName: string, scriptUrl: string, callbackFn?: () => any) => {
  const scriptId = `script-${scriptName}`;

  if (document.getElementById(scriptId)) {
    callbackFn && callbackFn();
    return;
  }

  const script = document.createElement('script');
  script.id = scriptId;
  script.src = scriptUrl;
  callbackFn && (script.onload = callbackFn);
  document.head.appendChild(script);

  script.onerror = () => {
    console.log(`Error loading file - ${scriptName}`);

    if (script.parentElement) {
      script.parentElement.removeChild(script);
    }
  };
};
